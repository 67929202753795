/* UmrahTrip.css */
.umrah-container {
    padding: 20px;
  }
  
  .umrah-section {
    margin-bottom: 40px;
  }
  
  .pictures-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .umrah-picture {
    width: calc(30% - 10px);
    height: auto;
    border-radius: 5px;
    object-fit: cover;
  }

.home-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #8c98ca
  }

.umrah-video {
  width: 50%;
  max-width: 400px;
  height: auto;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
}