/* Ramadan.css */
.ramadan-container {
  padding: 40px 20px;
  background: linear-gradient(to bottom, #f9f9f9, #ffffff);
  min-height: 100vh;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  /* color: #1a1a1a; */
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
}

.text-group {
  margin-bottom: 3rem;
  max-width: 800px;
  margin: 0 auto 3rem;
}

.quran-quote {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.arabic-text {
  font-size: 2rem;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.8;
  font-family: 'Traditional Arabic', serif;
}

.english-text {
  font-size: 1.125rem;
  color: #444;
  text-align: center;
  line-height: 1.6;
}

.quote-translation {
  font-style: italic;
}

.hadith-section {
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.hadith {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.hadith-source {
  color: #666;
  text-align: center;
  font-style: italic;
}

.invitation {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.video-wrapper {
  flex: 0 1 auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.video-wrapper:hover {
  transform: translateY(-5px);
}

video, iframe {
  border: none;
  border-radius: 15px;
  display: block;
}

@media (max-width: 768px) {
  .ramadan-container {
    padding: 20px 15px;
  }

  .title {
    font-size: 2rem;
  }

  .arabic-text {
    font-size: 1.75rem;
  }

  .english-text {
    font-size: 1rem;
  }

  .video-container {
    gap: 1rem;
  }

  .video-wrapper {
    width: 100%;
    max-width: 350px;
  }
}
